<template>
  <div id="UserList">
    <title-nav :title="'회원탈퇴'" :nav="'회원관리 / 탈퇴현황'" />
    <b-container fluid>
      <div class="card">
        <div class="card-header">
          <search :isDate="true" @search="item => setCondition(item)"></search>
        </div>
        <div class="card-body p-0">
          <b-table
            striped
            hover
            bordered
            class="table-sm text-sm-center"
            :borderless="borderless"
            :dark="dark"
            :fixed="fixed"
            :no-border-collapse="noCollapse"
            :items="items"
            :fields="fields"
            :head-variant="headVariant"
            :busy="isBusy"
            show-empty
          >
            <template v-slot:table-busy>
              <div class="text-center text-danger my-2">
                <b-spinner class="align-middle"></b-spinner>
                <strong>Loading...</strong>
              </div>
            </template>
            <template v-slot:cell(user_sn)="data">
              {{ data.value }}
            </template>
            <template v-slot:cell(email)="data">
              {{ data.value }}
            </template>
            <template v-slot:cell(user_name)="data">
              {{ data.value }}
            </template>
            <template v-slot:cell(leave_dt)="data">
              {{ data.value | dateFilter }}
            </template>
            <template v-slot:cell(reg_dt)="data">
              {{ data.value | dateFilter }}
            </template>
            <template v-slot:cell(provider_cd)="data">
              {{ data.value }}
            </template>

            <template v-slot:empty>
              <h4>데이터가 없습니다.</h4>
            </template>
          </b-table>
        </div>
        <div class="card-footer clearfix">
          <b-pagination
            v-model="page"
            :total-rows="records"
            :per-page="rowPerPage"
            @change="setPage"
            align="right"
          ></b-pagination>
        </div>
      </div>
    </b-container>
  </div>
</template>

<script>
import Search from '../util/Search'
import { getUserWithdrawawl, getUserCode } from '@/api/user'

export default {
  name: 'withdrawal',
  components: {
    Search
  },
  data() {
    return {
      page: 1,
      records: 0,
      rowPerPage: 20,
      modalPage: 1,
      isBusy: false,
      nickname: '',
      email: '',
      phoneno: '',
      invite_email: '',

      selectedNum: '',
      fields: [
        {
          key: 'user_sn',
          label: '회원번호',
          colType: 'span'
        },
        {
          key: 'email',
          label: '이메일',
          colType: 'span',
          tdClass: 'text-left'
        },
        {
          key: 'user_name',
          label: '닉네임',
          colType: 'span'
        },
        {
          key: 'real_name',
          label: '이름',
          colType: 'span'
        },
        {
          key: 'reg_dt',
          label: '가입일자',
          colType: 'span'
        },
        {
          key: 'leave_dt',
          label: '탈퇴일자',
          colType: 'span'
        },
        {
          key: 'provider_cd',
          label: '가입방법',
          colType: 'span'
        }
      ],
      items: [],
      striped: true,
      bordered: true,
      borderless: false,
      small: true,
      hover: true,
      dark: false,
      fixed: false,
      tableVariant: '',
      noCollapse: false,
      headVariant: 'dark',
      code: '',
      search: null
    }
  },
  methods: {
    getCodeName(code) {
      const name = this.code && this.code.find(value => value.cd_value === code).cd_name_kr
      return name
    },
    async init() {
      this.isBusy = true
      const response = await getUserWithdrawawl(this.page, this.rowPerPage, '', '')
      if (response.code === '0000') {
        this.items = response.data.list
        this.lastPage =
          Math.floor(response.data.totCnt / this.rowPerPage) +
          (response.data.totCnt % this.rowPerPage === 0 ? 0 : 1)
      } else {
        alert(response.message)
      }
      this.isBusy = false
    },
    async getList() {
      this.isBusy = true
      const response = await getUserWithdrawawl(
        this.page,
        this.rowPerPage,
        this.search.key,
        this.search.value,
        this.search.start,
        this.search.end
      )
      if (response.code === '0000') {
        this.items = response.data.list
        this.records = response.data.totCnt
      } else {
        alert(response.message)
      }
      this.isBusy = false
    },
    setPage(page) {
      this.page = page
      this.getList()
    },
    setCondition(search) {
      console.error(search)
      this.usersn = this.email = ''
      switch (search.key) {
        case 'usersn':
          this.usersn = search.value
          break
        case 'email':
          this.email = search.value
          break
        case 'nickname':
          this.email = search.value
          break
        case 'referID':
          this.email = search.value
          break
      }
      this.page = 1
      this.search = search
      this.getList()
    },
    sendInfo(num) {
      this.selectedNum = num
      // this.getDetail(this.selectedNum)
    },
    openRecommList() {
      this.$refs['modal_recom_list'].show()
    },
    async setCodeList() {
      const response = await getUserCode()
      if (response.code === '0000') {
        this.code = response.data
      } else {
        alert(response.message)
      }
    }
  },
  mounted() {
    this.setCodeList()
    this.init()
  }
}
</script>
